import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Wrapper = styled.div`
  text-align: center;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles[theme.module.style])}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
  
  h1, h2 {
    ${({ theme }) =>
      renderStyledElementStyles(theme, theme.module.vStyles.elements.heading)}
  }

  p {
    ${({ theme }) =>
      renderStyledElementStyles(theme, theme.module.vStyles.elements.paragraph)}
  }

  a {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.callToAction,
      )}

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles[theme.module.style].elements?.callToAction,
      )}
  }

  p,
  li,
  li::marker,
  h1,
  h2 {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.textStyles[theme.module.textStyle],
      )}
  }
`;
