import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledHeading,
  Thumbnail,
  VideoModal,
  VideoPlayer,
} from '../../../sharedComponents';
import { Line } from '../../styles';
import { VideoSource } from '../../../../../../types';

export const FullDisplay = ({
  id,
  source,
  thumbnail,
  poster,
  title,
  titleSize,
  modalOpened,
  toggleModal,
  streamlyUrl,
}) => (
  <>
    <Thumbnail
      path={thumbnail}
      imgix={!!poster}
      onClick={toggleModal}
      testId="video-thumbnail-full"
    >
      {title && (
        <StyledHeading content={title} as={titleSize} testId="video-title" />
      )}
      <Line data-testid="video-line" />
    </Thumbnail>
    {modalOpened && (
      <VideoModal
        onClose={toggleModal}
        close={{ type: 'button', text: 'close' }}
      >
        <VideoPlayer
          source={source}
          id={id}
          title={title}
          streamlyUrl={streamlyUrl}
        />
      </VideoModal>
    )}
  </>
);

FullDisplay.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.oneOf(Object.values(VideoSource)).isRequired,
  thumbnail: PropTypes.string.isRequired,
  poster: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.string,
  streamlyUrl: PropTypes.string,
  modalOpened: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};
