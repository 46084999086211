import styled from 'styled-components';
import { devices, renderStyledElementStyles } from '../../../../../../helpers';
import { VideoModulePositions } from '../../../../types';

export const TextWrapper = styled.div`
  margin-top: 20px;
  @media screen and (min-width: ${devices.breakpoints.tablet}) {
    margin-top: 0;
    padding-right: 15px;
  }

  p {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles[VideoModulePositions.RIGHT]?.elements?.paragraph,
      )}
  }
`;
