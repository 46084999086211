import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../helpers';

export const Line = styled.div`
  width: 100%;
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.line)}

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles[theme.module.style].elements?.line,
    )}

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.line)}
`;
