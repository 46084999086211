import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import {
  StyledCallToAction,
  StyledHeading,
  StyledParagraph,
  Thumbnail,
} from '../../../../../sharedComponents';
import { Line } from '../../../../styles';

import { Wrapper } from './index.style';
import cn from 'classnames';

export const BottomPosition = (props) => {
  const {
    thumbnail,
    poster,
    title,
    titleSize,
    text,
    link,
    toggleModal,
    standardVideoWidth,
    videoPlay,
    playerComponent,
  } = props;
  const themeConfig = useContext(ThemeContext);
  const { dStyles, videoPosition } = themeConfig.module;
  const {
    elements: { imageTag: { settings: { sm = 8, pos = '' } = {} } = {} },
  } = dStyles[videoPosition];
  const [col, offset] = [sm, (12 - sm) / 2];

  const headingGridClassName = cn('col-xs-12', {
    [`col-sm-${col} col-sm-offset-${offset} ${pos}`]: standardVideoWidth,
  });
  const thumbnailGridClassName = cn('col-xs-12', {
    'col-xs-12 col-sm-8 col-sm-offset-2 last-xs': standardVideoWidth,
  });
  const paragraphGridClassName = cn('col-xs-12', {
    'col-xs-12 col-sm-6 col-sm-offset-3': standardVideoWidth,
  });

  return (
    <Wrapper data-testid="video-bottom">
      <div className="container">
        {title && (
          <div className="row">
            <div className={headingGridClassName}>
              <StyledHeading
                content={title}
                as={titleSize}
                testId="video-title"
              />
            </div>
          </div>
        )}
        {standardVideoWidth && (
          <div className="row">
            <Line data-testid="video-line" />
          </div>
        )}
        <div className={paragraphGridClassName}>
          <StyledParagraph content={text} testId="video-text" />
        </div>
        <div className="row">
          <div className={thumbnailGridClassName}>
            {videoPlay ? (
              playerComponent
            ) : (
              <Thumbnail
                path={thumbnail}
                imgix={!!poster}
                onClick={toggleModal}
                testId="video-thumbnail"
              />
            )}
          </div>
        </div>
        {link?.shown && (
          <div className="row">
            <div className="col-xs-12">
              <StyledCallToAction ctaConfig={link} testId="video-cta" />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

BottomPosition.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  poster: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.string,
  text: PropTypes.string.isRequired,
  link: PropTypes.shape({
    shown: PropTypes.bool,
  }),
  toggleModal: PropTypes.func.isRequired,
  standardVideoWidth: PropTypes.bool.isRequired,
  videoPlay: PropTypes.bool.isRequired,
  playerComponent: PropTypes.node,
};
