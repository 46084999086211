import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../../../helpers';
import { VideoModulePositions } from '../../../../types';

export const Wrapper = styled.div`
  h2 {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles[VideoModulePositions.TOP].elements.heading,
      )}
  }

  p {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles[VideoModulePositions.TOP].elements.paragraph,
      )}
  }
`;
